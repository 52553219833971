<template>
  <section class="job-collection">
    <div class="job-collection__content constrain-content--small center">
      <h2 class="margin-bottom--large">Let your agent keep track <br/>of job opportunities</h2>
      <!-- <select class="job-collection__job-categories">
        <option>Most popular</option>
        <option>Recently posted</option>
        <option>Student and Junior jobs</option>
        <option>Remote jobs</option>
      </select> -->
      <div class="job-carousel">
        <template v-for="job in dummy_jobs">
          <job-collection
            :key="job.title"
            :icon="job.icon"
            :title="job.title"
            :description="job.description"
            :tags="job.tags"
            :button="job.button"
          ></job-collection>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {
    JobCollection: () => import("@/components/job-card/job-card"),
  },
  data() {
    return {
      dummy_jobs: [
        {
          icon: require('@/assets/ico-color/java.svg'),
          title: "Backend Engineer",
          description: "Fintech | 50+ employees",
          tags: ["Java", "Springboot", ".NET Core"],
          button: {
            title: "Find similar jobs",
            action: "#",
          },
        },
        {
          icon: require('@/assets/ico-color/docker.svg'),
          title: "DevOps Engineer",
          description: "Medtech | 100+ employees",
          tags: ["Docker", "Kubernetes", "AWS", "Python"],
          button: {
            title: "Find similar jobs",
            action: "#",
          },
        },
        {
          icon: require('@/assets/ico-color/react.svg'),
          title: "Frontend Developer",
          description: "Consumer products | 250+ employees",
          tags: ["Typescript", "React", "Figma"],
          button: {
            title: "Find similar jobs",
            action: "#",
          },
        },
        {
          icon: require('@/assets/ico-color/swift.svg'),
          title: "Mobile Developer",
          description: "B2B Services | 75+ anställda",
          tags: ["Swift", "Objective-C", "Xamarin"],
          button: {
            title: "Find similar jobs",
            action: "#",
          },
        },
      ],
    };
  },
  mounted() {},
};
</script>
<style lang="scss">
.job-collection {
    background-color: var(--accent-color-3);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;    
    @include max-sm {

        &__content {
            max-width: 100%;
        }
        .job-carousel {
            margin-left: calc(-1 * var(--section-padding-horisontal-default));
            margin-right: calc(-1 * var(--section-padding-horisontal-default));
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            scroll-behavior: smooth;

            // Implement this for better scrolling
            // https://stackoverflow.com/questions/58863384/increase-sensitivity-of-scroll-snapping-in-google-chrome

            &::-webkit-scrollbar {
                display: none;
            }
            .job-card {
                flex: 0 0 90%;
                scroll-snap-align: start;
                scroll-snap-stop: always;
                &__body {
                    margin: 0 1rem;
                }
            }
        }
    }
    @include sm {
        .job-carousel {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}



</style>